import { createSlice } from "@reduxjs/toolkit"

export const programMembershipsSlice = createSlice({
  name: "programMemberships",
  initialState: {
    reportSentAt: null,
    reportOpenedAt: null,
    filePath: "",
    reportPath: "",
    isLoading: false,
    isPdfSuccessful: false,
    isReportSuccessful: false,
  },
  reducers: {
    updateFilePath: (state, action) => {
      state.filePath = action.payload
    },
    updateReportPath: (state, action) => {
      state.reportPath = action.payload
    },
    updateLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updatePdfSuccessful: (state, action) => {
      state.isPdfSuccessful = action.payload
    },
    updateReportSuccessful: (state, action) => {
      state.isReportSuccessful = action.payload
    },
    updateReportSentAt: (state, action) => {
      state.reportSentAt = action.payload
    },
    updateReportOpenedAt: (state, action) => {
      state.reportOpenedAt = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateReportOpenedAt,
  updateReportSentAt,
  updateReportPath,
  updateFilePath,
  updateLoading,
  updatePdfSuccessful,
  updateReportSuccessful,
} = programMembershipsSlice.actions

export default programMembershipsSlice.reducer

import React, { useEffect } from "react"
import Autocomplete from "../autocomplete"
import { getUsers } from "repository/users_repository"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Users = (props: any) => {
  const role = props.role
  const [options, setOptions] = React.useState([])

  useEffect(() => {
    if (options.length == 0) {
      const fetchUsers = async () => {
        const users = await getUsers({
          include: "profile",
          filters: { roles: role },
          limit: 1000,
        })

        users.sort((a, b) => {
          const nameA = a.profile.name.toUpperCase()
          const nameB = b.profile.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })

        const filtered = users.map((user) => {
          const firstLetter = user.profile.name[0].toUpperCase()
          return {
            id: user.hashid,
            label: user.profile.name,
            group: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          }
        })
        if (filtered.length > 0) {
          setOptions(filtered)
        }
      }

      fetchUsers().catch(console.error)
    }
  }, [options, role])

  return <Autocomplete {...props} options={options} />
}

Users.displayName = "Users"
export default Users

import Button from "@mui/material/Button"

const btnStyle = {
  py: "4px",
  px: 2,
  fontSize: ".8rem",
  borderRadius: "9999px",
  border: "1px solid rgb(55 225 120)",
  backgroundColor: "rgb(55 225 120)",
  color: "#000",
  textAlign: "center",
  whiteSpace: "nowrap",
  width: "auto",

  "&:hover": {
    border: "1px solid #000",
    backgroundColor: "#FFF",
  },
}

const UnloqButton = (props) => {
  return (
    <Button sx={btnStyle} {...props}>
      {props.children}
    </Button>
  )
}

export default UnloqButton

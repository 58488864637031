import { ClientSession } from "api/models"

export async function getClientSessions(filters) {
  const where = {}
  for (const i in filters) {
    where[filters[i].name] = filters[i].value
  }
  const { data } = await ClientSession.where(where)
    // .page(2).per(10)
    .order({ date: "desc" })
    .includes("program,owner,owner.profile,client,client.profile")
    .all()

  return data
}

import Badge from "@mui/material/Badge"
import ChatBubbleIcon from "@mui/icons-material/ChatBubble"
import { Box, IconButton } from "@mui/material"
import UnloqTheme from "./unloq_theme"
import { useContext, useEffect, useState, Fragment } from "react"
import { UnreadCommentsContext } from "../context/unread_comments_context"

const CommentsIcon = ({
  unread_comments = 0,
  total_comments = 0,
  click = null,
  location,
}) => {
  const unreadComments = useContext(UnreadCommentsContext)
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    if (unreadComments === null) {
      setUnreadCount(unread_comments)
    } else {
      if (location === "top") {
        setUnreadCount(unreadComments)
      }
    }
  }, [unreadComments, unread_comments, location])

  return (
    <UnloqTheme>
      <IconButton>
        {unreadCount > 0 && (
          <Badge badgeContent={unreadCount} color="primary" max={9}>
            <ChatBubbleIcon onClick={click} />
          </Badge>
        )}
        {unreadCount == 0 && (
          <Fragment>
            <ChatBubbleIcon onClick={click} />
            <Box
              sx={{
                position: "absolute",
                color: "#fff",
                fontSize: "10px",
                marginTop: "-3px",
              }}
            >
              {total_comments > 0 && total_comments}
            </Box>
          </Fragment>
        )}
      </IconButton>
    </UnloqTheme>
  )
}
export default CommentsIcon

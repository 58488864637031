/* eslint-disable */

import * as React from "react"
import dayjs, { Dayjs } from "dayjs"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"

export const UnloqDatepicker = ({ submit, initial, label, name }) => {
  const [value, setValue] = React.useState<Dayjs | null>(null)
  const [hiddenValue, setHiddenValue] = React.useState<string>(initial)

  React.useEffect(() => {
    if (initial !== "") {
      setValue(dayjs(initial))
    }
  }, [initial])

  React.useEffect(() => {
    if (value != null) {
      const formatted = value.format("YYYY-MM-DD")
      if (formatted != initial) {
        setHiddenValue(formatted)
      }
    }
  }, [value])

  React.useEffect(() => {
    if (hiddenValue != "" && hiddenValue != initial) {
      document.getElementById(submit).click()
    }
  }, [hiddenValue])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TextField name={name} value={hiddenValue} sx={{ display: "none" }} />
      <DatePicker
        value={value}
        label={label}
        sx={{ width: "100%" }}
        onChange={(newValue) => {
          setValue(dayjs(newValue))
        }}
      />
    </LocalizationProvider>
  )
}

export default UnloqDatepicker

import React, { useEffect } from "react"
import Autocomplete from "../autocomplete"
import { getPeers } from "repository/peers_repository"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Users = (props: any) => {
  const role = props.role
  const [options, setOptions] = React.useState([])

  const getFullName = (peer) => {
    let name = peer.firstName
    if (peer.lastName) {
      name += ` ${peer.lastName}`
    }
    return name
  }

  useEffect(() => {
    if (options.length == 0) {
      const fetchPeers = async () => {
        const peers = await getPeers({ limit: 1000 })
        const filtered = peers.map((peer) => {
          const fullName = getFullName(peer)
          const firstLetter = fullName[0].toUpperCase()
          return {
            id: peer.hashid,
            label: fullName,
            group: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          }
        })
        if (filtered.length > 0) {
          setOptions(filtered)
        }
      }

      fetchPeers().catch(console.error)
    }
  }, [options, role])

  return <Autocomplete {...props} options={options} />
}

Users.displayName = "Users"
export default Users

import { configureStore } from "@reduxjs/toolkit"
import programMembershipsSlice from "lib/features/program-membership-slice"

const store = configureStore({
  reducer: {
    programMemberships: programMembershipsSlice,
  },
})
export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

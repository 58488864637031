import React, { useEffect, Fragment } from "react"
import Autocomplete from "../autocomplete_select"
import { getPrograms } from "../../../repository/programs_repository"
import { IFilter } from "components/interfaces"

export const ProgramSelect = (props: {
  filters: Array<IFilter>
  setFilter
}) => {
  const { filters, setFilter } = props

  const [options, setOptions] = React.useState([])

  useEffect(() => {
    if (options.length == 0) {
      const fetchPrograms = async () => {
        const programs = await getPrograms({
          include: "organization",
          filters: filters,
        })
        const filtered = programs.map((program) => {
          return {
            id: program.hashid,
            label: program.name,
            group: program.organization.name,
          }
        })
        setOptions(filtered)
      }

      fetchPrograms().catch(console.error)
    }
  }, [filters, options])

  return (
    <Fragment>
      {options.length > 0 && (
        <Autocomplete
          options={options}
          label="Select program"
          filterName="program"
          setFilter={setFilter}
          filters={filters}
        />
      )}
    </Fragment>
  )
}

export default ProgramSelect

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { IFilter } from "../../components/interfaces"

const UnloqAutocomplete = (props: {
  options
  label: string
  filters: Array<IFilter>
  filterName: string
  setFilter
}) => {
  const { options, label, filters, filterName, setFilter } = props

  const [value, setValue] = React.useState<string | null>(null)

  const updateSelected = (newValue) => {
    setValue(newValue)

    if (newValue != null) {
      const filter: IFilter = {
        name: "program",
        value: newValue.id,
        presentation: `Program: ${newValue.label}`,
      }
      setFilter(filter)
    } else {
      const filter: IFilter = {
        name: "program",
        value: null,
      }
      setFilter(filter)
    }
  }

  // This clears the value when in the chips the x is pressed to remove a filter
  useEffect(() => {
    if (filters && filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].name == filterName && filters[i].value == null) {
          setValue(null)
        }
      }
    }
  }, [filters, filterName])

  return (
    <Autocomplete
      disablePortal
      options={options}
      value={value}
      groupBy={(option) => option.group}
      onChange={(event: any, newValue: any | null) => {
        updateSelected(newValue)
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      sx={{ width: "100%", backgroundColor: "#ffffff" }}
    />
  )
}

export default UnloqAutocomplete

import { Fragment } from "react"
import * as toastr from "toastr"
import { ChatBubbleOutlineRounded, InfoOutlined } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import { RootState } from "lib/store"
import { Box } from "@mui/material"

const i18n = new I18n(translations)

const SendReportDialogButton = () => {
  const filePath = useSelector(
    (state: RootState) => state.programMemberships.filePath
  )
  const isLoading = useSelector(
    (state: RootState) => state.programMemberships.isLoading
  )

  const sendReport = async (): Promise<{ url: string }> => {
    const form = document.getElementById(
      "people_analytics_membership"
    ) as HTMLFormElement
    const formData = new FormData(form)

    return await fetch(form.action + "/send_report_dialog_message", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          toastr.error("An unknown error occurred.")
        } else {
          return response
        }
      })
      .then((response) => response.json())
      .then((response) => response)
  }

  return (
    <Box
      sx={{ m: 0, position: "relative" }}
      className="flex items-center justify-center"
    >
      {!isLoading && filePath && (
        <Fragment>
          <button
            className="btn btn--primary"
            onClick={async (e) => {
              e.preventDefault()
              const response = await sendReport()
              window.location.href = response.url
            }}
          >
            <ChatBubbleOutlineRounded
              fontSize="small"
              sx={{ marginRight: 1 }}
            />
            {i18n.t("react.admin.people_analytics.btn_send_report_link_dialog")}
          </button>
          <div className="tooltip flex relative cursor-pointer ml-3">
            <InfoOutlined fontSize="small" sx={{ marginRight: 1 }} />
            <span className="tooltiptext tooltiptext-top">
              {i18n.t(
                "react.admin.people_analytics.btn_send_report_link_dialog_hint"
              )}
            </span>
          </div>
        </Fragment>
      )}
    </Box>
  )
}

SendReportDialogButton.displayName = "SendReportDialogButton"

export default SendReportDialogButton

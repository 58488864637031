import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"

function statusSymbol(status: string): string {
  let symbol = "-"
  if (status == "Failed" || status == "Not started") {
    symbol = "❌"
  } else if (status == "No-show" || status == "Absent") {
    symbol = "🤷"
  } else if (
    status == "Pending" ||
    status == "Pending feedback" ||
    status == "Pending attendance feedback"
  ) {
    symbol = "⌛️"
  } else if (status == "Started") {
    symbol = "✍️"
  } else if (status == "Completed") {
    symbol = "✅"
  } else if (status == "-") {
    symbol = "-"
  } else {
    symbol = status
  }
  return symbol
}

const StatusIcon = ({ status }) => {
  return <IconButton sx={{ fontSize: 15 }}>{statusSymbol(status)}</IconButton>
}

export default function LearningPathTable({ steps }) {
  const stepsJson = JSON.parse(steps)

  return (
    <TableContainer component={Paper}>
      <Table aria-label="over time steps table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "rgb(247 248 248)",
              border: "1px solid rgb(224 228 232)",
            }}
          >
            <TableCell>Client</TableCell>
            <TableCell>Step</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Overdue</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stepsJson.map((step, i) => (
            <TableRow key={`row-${i}`}>
              <TableCell>{step.client}</TableCell>
              <TableCell>{step.step}</TableCell>
              <TableCell>
                <StatusIcon status={step.status} />
                {step.status}
              </TableCell>
              <TableCell>{step.time_ago} ago</TableCell>
              <TableCell>
                <a
                  href={`mailto:${step.client}?subject=This is just an example!&body=This is only a test!`}
                >
                  E-mail client
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

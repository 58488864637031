/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios"
import { useReducer } from "react"
import {
  UnreadCommentsContext,
  UnreadCommentsDispatchContext,
} from "../context/unread_comments_context"

function unreadCommentsReducer(state = null, count) {
  return count
}

export const UnloqContainer = (props) => {
  const [unreadComments, dispatch] = useReducer(unreadCommentsReducer, null)

  const asyncDispatch = () => {
    axios
      .get("/api/v1/users/unread_comments")
      .then(function (response) {
        dispatch(response.data.unread_comments)
      })
      .catch(function () {
        // TODO Sentry
      })
  }

  return (
    <UnreadCommentsContext.Provider value={unreadComments}>
      <UnreadCommentsDispatchContext.Provider value={asyncDispatch}>
        {props.children}
      </UnreadCommentsDispatchContext.Provider>
    </UnreadCommentsContext.Provider>
  )
}

import Box from "@mui/material/Box"
import React from "react"

import Filters from "../components/filters"
import { IFilter } from "../components/interfaces"

import DefaultLayout from "../layout/default_layout"
import ImportIcon from "@mui/icons-material/Download"
import ExportIcon from "@mui/icons-material/Upload"

import Programs from "../components/select/autocomplete/program_select"

import ClientSessionsTable from "../components/tables/client_sessions_table"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

export const ClientSessionsDashboard = () => {
  const [filters, setFilters] = React.useState([])

  const setFilter = (filter: IFilter) => {
    const newFilters = []
    if (filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].name === filter.name) {
          newFilters.push(filter)
        } else {
          newFilters.push(filters[i])
        }
      }
    } else {
      newFilters.push({
        name: filter.name,
        value: filter.value,
        presentation: filter.presentation,
      })
    }
    setFilters(newFilters)
  }

  return (
    <DefaultLayout>
      <Typography variant="h3" gutterBottom>
        Client sessions
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <Button variant="text" startIcon={<ImportIcon />}>
          Import
        </Button>
        <Button variant="text" startIcon={<ExportIcon />}>
          Export
        </Button>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Filters filters={filters} setFilter={setFilter}>
          <Programs filters={filters} setFilter={setFilter} />
        </Filters>
      </Box>
      <ClientSessionsTable filters={filters} />
    </DefaultLayout>
  )
}

export default ClientSessionsDashboard

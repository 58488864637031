import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Model,
  SpraypaintBase,
} from "spraypaint"

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = ""
  static apiNamespace = "/api/v1"
}

@Model()
export class ClientSession extends ApplicationRecord {
  static jsonapiType = "clientSessions"
  static endpoint = "/client-sessions"

  @HasOne() client: Client
  @HasOne() owner: Owner
  @HasOne() program: Program

  @Attr() hashid: string
  @Attr() date: string
  @Attr() sessions: string
  @Attr() hourlyRate: string
  @Attr() billable: string
  @Attr() nonBillable: string
  @Attr() available: string
  @Attr() remaining: string
  @Attr() progress: string
}

@Model()
export class Program extends ApplicationRecord {
  static jsonapiType = "programs"

  @HasOne() profile: Profile
  @HasOne() organization: Organization

  @Attr() hashid: string
  @Attr() name: string
}

@Model()
export class Organization extends ApplicationRecord {
  static jsonapiType = "organizations"

  @Attr() hashid: string
  @Attr() name: string
}

@Model()
export class User extends ApplicationRecord {
  static jsonapiType = "users"

  @HasOne() profile: Profile

  @Attr() hashid: string
  @Attr() email: string
  @Attr() first_name: string
  @Attr() last_name: string
  @Attr() name: string
  @Attr() curriculum_vitae_id: number
  @Attr() commercial_curriculum_vitae_id: number
  @Attr() profile_id: number
}

@Model()
export class Client extends ApplicationRecord {
  static jsonapiType = "clients"

  @HasOne() profile: Profile

  @Attr() hashid: string
}

@Model()
export class Owner extends ApplicationRecord {
  static jsonapiType = "owner"

  @HasOne() profile: Profile

  @Attr() hashid: string
}

@Model()
export class Coach extends ApplicationRecord {
  static jsonapiType = "coach"

  @HasOne() profile: Profile

  @Attr() hashid: string
}

@Model()
export class Peer extends ApplicationRecord {
  static jsonapiType = "peers"

  @Attr() hashid: string
  @Attr() firstName: string
  @Attr() lastName: string
}

@Model()
export class Profile extends ApplicationRecord {
  static jsonapiType = "profiles"

  @HasOne() residence_country: Country
  @HasOne() timezone: Timezone
  @HasMany() specializations: Specialization
  @HasMany() professions: Profession

  @Attr() organization_name: string
  @Attr() hashid: string
  @Attr() name: string
  @Attr() linkedin: string
  @Attr() id_number: string
  @Attr() place_of_birth: string
  @Attr() native_background: string
  @Attr() citizenship: string
  @Attr() remarks: string
  @Attr() avatar_picture: {
    src: string
  }
}

@Model()
export class CurriculumVitae extends ApplicationRecord {
  static jsonapiType = "curriculum-vitaes"

  @Attr() job_title: LocalizedString
}

@Model()
export class Comment extends ApplicationRecord {
  static jsonapiType = "comments"
  @Attr() hashid: string
  @Attr() shared: string
  @Attr() text: string
  @Attr() commentableType: string
  @Attr() commentableId: string
  @Attr() createdAt: string
  @Attr() userId: string
  @Attr() userName: string
  @Attr() avatarUrl: string
  @BelongsTo() user: User
  @BelongsTo() subject: Subject
  @BelongsTo() repliedComment: Comment
  @Attr() subjectId: number
  @Attr() repliedCommentId: number
  @Attr() readByMe: boolean
}

@Model()
export class Assignment extends ApplicationRecord {
  static jsonapiType = "assignments"

  @Attr() hashid: string
  @Attr() name: string
  @Attr() targetGroup: string
  @Attr() previewUrl: string
}

@Model()
export class Questionnaire extends ApplicationRecord {
  static jsonapiType = "questionnaires"

  @Attr() hashid: string
  @Attr() name: string
}

@Model()
export class Subject extends ApplicationRecord {
  static jsonapiType = "subjects"

  @Attr() hashid: string
  @Attr() title: string
}

@Model()
export class Country extends ApplicationRecord {
  static jsonapiType = "countries"

  @Attr() code: string
  @Attr() common_name: string
  @Attr() region_name: string
}

@Model()
export class Profession extends ApplicationRecord {
  static jsonapiType = "professions"

  @Attr() name: LocalizedString
}

@Model()
export class Timezone extends ApplicationRecord {
  static jsonapiType = "timezones"

  @Attr() name: string
  @Attr() full_name: string
  @Attr() offset: string
}

@Model()
export class BranchExperience extends ApplicationRecord {
  static jsonapiType = "branch_experiences"

  @Attr() name: LocalizedString
}

@Model()
export class CertificationLevel extends ApplicationRecord {
  static jsonapiType = "certification_levels"

  @Attr() name: LocalizedString
}

@Model()
export class Specialization extends ApplicationRecord {
  static jsonapiType = "specializations"

  @Attr() name: LocalizedString
}

@Model()
export class Language extends ApplicationRecord {
  static jsonapiType = "languages"

  @Attr() code: string
  @Attr() common_name: LocalizedString
}

@Model()
export class ProfileLanguage extends ApplicationRecord {
  static jsonapiType = "profile-languages"

  @BelongsTo() profile: Profile
  @BelongsTo() language: Language
  @BelongsTo() language_level: LanguageLevel
  @Attr() language_level_id: number
}

@Model()
export class LanguageLevel extends ApplicationRecord {
  static jsonapiType = "language-levels"

  @Attr() id: string
  @Attr() name: LocalizedString
}

@Model()
export class Region extends ApplicationRecord {
  static jsonapiType = "regions"

  @Attr() name: LocalizedString
}

@Model()
export class LearningStepProgress extends ApplicationRecord {
  static jsonapiType = "learning_step_progresses"
  static endpoint = "/learning-step-progresses"

  @HasOne() LearningStep: LearningStep

  @Attr() status: string
  @Attr() modifiedAt: string
  @Attr() learningStepId: number
  @Attr() programMembershipId: number
  @Attr() programId: number
}

@Model()
export class LearningModule extends ApplicationRecord {
  static jsonapiType = "learning_modules"
  static endpoint = "/learning-modules"

  @HasMany() learningSteps: LearningStep

  @Attr() name: string
  @Attr() colorDark: string
  @Attr() colorLight: string
  @Attr() startDays: string
  @Attr() endDays: string
}

@Model()
export class LearningStep extends ApplicationRecord {
  static jsonapiType = "learning_steps"
  static endpoint = "/learning-steps"

  @HasOne() learningModule: LearningModule
  @HasMany() learningStepProgress: LearningStepProgress

  @Attr() includeInReport: boolean
  @Attr() name: string
  @Attr() startDays: string
  @Attr() endDays: string
}

@Model()
export class AssociateListUser extends ApplicationRecord {
  static jsonapiType = "associate_list_users"
  static endpoint = "/associate-list-users"

  @HasOne() user_id: User

  @Attr() position: number
}

export class LocalizedString {
  en: string
  es: string
  fr: string
  nl: string
}

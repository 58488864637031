/* eslint-disable react/prop-types */

import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
} from "@mui/material"
import React from "react"
import { LearningStepProgress } from "api/models"
import { getLearningStepProgresses } from "repository/learning_step_progresses_repository"

const statuses = [
  "Absent",
  "Failed",
  "Not started",
  "No-show",
  "Pending",
  "Pending feedback",
  "Pending attendance feedback",
  "Started",
  "Completed",
  "-",
]

export const statusSymbol = (status: string): string => {
  let symbol = "-"
  if (status == "Failed" || status == "Not started") {
    symbol = "❌"
  } else if (status == "No-show" || status == "Absent") {
    symbol = "🤷"
  } else if (
    status == "Pending" ||
    status == "Pending feedback" ||
    status == "Pending attendance feedback"
  ) {
    symbol = "⌛️"
  } else if (status == "Started") {
    symbol = "✍️"
  } else if (status == "Completed") {
    symbol = "✅"
  } else if (status == "-") {
    symbol = "-"
  } else {
    symbol = status
  }
  return symbol
}

const StatusIcon = ({ status }) => {
  return <IconButton sx={{ fontSize: 15 }}>{statusSymbol(status)}</IconButton>
}

export interface SimpleDialogProps {
  open: boolean
  selectedValue: string
  onClose: (value: string) => void
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value: string) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set status</DialogTitle>
      <List sx={{ pt: 0 }}>
        {statuses.map((status) => (
          <ListItem disableGutters key={status}>
            <ListItemButton onClick={() => handleListItemClick(status)}>
              {statusSymbol(status)}
              <ListItemText primary={status} sx={{ ml: 2 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default function LearningPathTableCell({ cellData, index }) {
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(cellData.data)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const submitStatusChange = async (value: string) => {
    const filters = [
      { name: "learningStepId", value: cellData.step_id },
      { name: "programMembershipId", value: cellData.membership_id },
    ]
    const fetchedStepProgresses = await getLearningStepProgresses({
      filters: filters,
    })

    if (fetchedStepProgresses.length >= 1) {
      fetchedStepProgresses.forEach((learningStepProgress) => {
        learningStepProgress.status = value
        learningStepProgress.save()
      })
    } else {
      const learningStepProgress = new LearningStepProgress({
        learningStepId: cellData.step_id,
        programMembershipId: cellData.membership_id,
        status: value,
      })
      learningStepProgress.save()
    }
  }

  const handleClose = (value: string) => {
    setOpen(false)
    setSelectedValue(value)
    submitStatusChange(value)
  }

  return (
    <TableCell
      key={`data-cell-${index}-${cellData.membership_id}-${cellData.step_id}`}
      sx={{ backgroundColor: cellData.color, textAlign: cellData.align }}
    >
      {index < 2 ? (
        cellData.data
      ) : (
        <Box onClick={handleClickOpen}>
          <StatusIcon status={selectedValue} />
        </Box>
      )}
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </TableCell>
  )
}

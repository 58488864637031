import { Peer } from "api/models"

export async function getPeers({
  include = "",
  order = {},
  filters = {},
  page = 1,
  limit = 10,
}) {
  const { data } = await Peer.where(filters)
    .page(page)
    .per(limit)
    .order(order)
    .includes(include)
    .all()

  return data
}

import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import CssBaseline from "@mui/material/CssBaseline"
import Toolbar from "@mui/material/Toolbar"
import Divider from "@mui/material/Divider"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import ClientsIcon from "@mui/icons-material/People"
import ProgramsIcon from "@mui/icons-material/Workspaces"
import SessionsIcon from "@mui/icons-material/HeadsetMic"
import DialogsIcon from "@mui/icons-material/Forum"
import NotificationsIcon from "@mui/icons-material/Notifications"
import MyAccountIcon from "@mui/icons-material/AccountCircle"
import SupportIcon from "@mui/icons-material/SupportAgent"
import UserGuideIcon from "@mui/icons-material/HelpCenter"
import SettingsIcon from "@mui/icons-material/Settings"

import Image from "mui-image"

import DefaultAppBar from "./default_app_bar"
import { lightTheme } from "./lightTheme"

import { ThemeProvider } from "@mui/material/styles"
import { Typography } from "@mui/material"

const drawerWidth = 240

export default function DefaultLayout(props) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <DefaultAppBar />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <Box sx={{ display: "flex", gap: 2, py: 3 }}>
              <Box sx={{ width: "50px", height: "50px" }}>
                <Image src="/unloq-icon.png" />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold", color: "white" }}>
                  UNLOQ
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Campus
                </Typography>
              </Box>
            </Box>
          </Toolbar>
          <Divider />
          <List>
            <ListItem key={"clients"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ClientsIcon />
                </ListItemIcon>
                <ListItemText primary={"Clients"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"programs"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ProgramsIcon />
                </ListItemIcon>
                <ListItemText primary={"Programs"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"sessions"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SessionsIcon />
                </ListItemIcon>
                <ListItemText primary={"Sessions"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"dialogus"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DialogsIcon />
                </ListItemIcon>
                <ListItemText primary={"Dialogs"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"notifications"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={"Notifications"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={"my_account"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <MyAccountIcon />
                </ListItemIcon>
                <ListItemText primary={"My account"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"settings"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={"support"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SupportIcon />
                </ListItemIcon>
                <ListItemText primary={"Support"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"user guide"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <UserGuideIcon />
                </ListItemIcon>
                <ListItemText primary={"User guide"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar sx={{ mb: 3 }} />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

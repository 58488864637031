import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { peopleAnalyticsReportChannel } from "lib/event-bus-channels"
import {
  updateFilePath,
  updateLoading,
  updatePdfSuccessful,
  updateReportOpenedAt,
  updateReportPath,
  updateReportSentAt,
  updateReportSuccessful,
} from "lib/features/program-membership-slice"

const ProgramMembershipsPage = ({
  file_path,
  report_opened_at,
  report_sent_at,
  report_path,
}: {
  report_sent_at: string
  report_opened_at: string
  file_path: string
  report_path: string
}) => {
  const dispatch = useDispatch()

  if (report_sent_at && file_path) {
    dispatch(updateReportSentAt(report_sent_at))
    dispatch(updateFilePath(file_path))
    dispatch(updateReportPath(report_path))
    dispatch(updateLoading(true))
  }

  if (report_opened_at) {
    dispatch(updateReportOpenedAt(report_opened_at))
  }

  useEffect(() => {
    const generateReport = async (): Promise<{
      file_path: string
      report_path: string
      report_sent_at: string
    }> => {
      const form = document.getElementById(
        "people_analytics_membership",
      ) as HTMLFormElement
      const formData = new FormData(form)

      return await fetch(form.action, { method: "POST", body: formData })
        .then((response) => {
          if (!response.ok) {
            dispatch(updateLoading(false))
            dispatch(updateReportSuccessful(false))
          } else {
            return response
          }
        })
        .then((response) => response.json())
        .then((response) => response)
    }

    // subscribe to events when mounting
    const unsubscribeOnGenerateReportButtonClick =
      peopleAnalyticsReportChannel.on(
        "onGenerateReportButtonClick",
        async () => {
          dispatch(updateLoading(true))
          dispatch(updateFilePath(""))

          const response = await generateReport()

          if (response.file_path) {
            dispatch(updateFilePath(response.file_path))
            dispatch(updateReportPath(response.report_path))
            dispatch(updateReportSentAt(response.report_sent_at))
          }
        },
      )

    const unsubscribeOnPdfLoadResult = peopleAnalyticsReportChannel.on(
      "onPdfLoadResult",
      async (success) => {
        dispatch(updateLoading(false))

        if (!success) {
          dispatch(updatePdfSuccessful(false))
        } else {
          dispatch(updatePdfSuccessful(true))
        }
      },
    )

    // unsubscribe events when unmounting
    return () => {
      unsubscribeOnGenerateReportButtonClick()
      unsubscribeOnPdfLoadResult()
    }
  }, [dispatch])

  return null
}

ProgramMembershipsPage.displayName = "ProgramMembershipsPage"

export default ProgramMembershipsPage

import React, { useEffect } from "react"
import Autocomplete from "../autocomplete"
import { getPrograms } from "repository/programs_repository"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Programs = (props: any) => {
  const [options, setOptions] = React.useState([])

  useEffect(() => {
    if (options.length == 0) {
      const fetchPrograms = async () => {
        const programs = await getPrograms({
          include: "organization",
          order: "organization.name,name",
          page: 1,
          limit: 1000,
        })
        const filtered = programs.map((program) => {
          return {
            id: program.hashid,
            label: program.name,
            group: program.organization.name,
          }
        })
        setOptions(filtered)
      }

      fetchPrograms().catch(console.error)
    }
  }, [options])

  return <Autocomplete {...props} options={options} />
}

Programs.displayName = "Programs"
export default Programs

import { LearningStepProgress } from "api/models"

export async function getLearningStepProgresses({
  include = "",
  order = {},
  filters = {},
  page = 1,
  limit = 10,
}) {
  const where = {}

  for (const i in filters) {
    where[filters[i].name] = filters[i].value
  }

  const { data } = await LearningStepProgress.where(where)
    .page(page)
    .per(limit)
    .order(order)
    .includes(include)
    .all()

  return data
}

/* eslint-disable */

import { useEffect, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CircleIcon from "@mui/icons-material/Circle"

import { getLearningModules } from "repository/learning_modules_repository"

const LearningPathOverview = ({ id }) => {
  const [learningModules, setLearningModules] = useState([])

  useEffect(() => {
    if (learningModules.length == 0) {
      const fetchLearningModules = async () => {
        const filters = [{ name: "learning_path", value: id }]
        const learningModules = await getLearningModules({
          filters: filters,
          include: "learning_steps",
        })
        setLearningModules(learningModules)
      }

      fetchLearningModules().catch(console.error)
    }
  }, [learningModules])

  return (
    <Box>
      {learningModules.map(function (learningModule) {
        return (
          <Paper
            sx={{ backgroundColor: "#f5f5f5", mb: 5, p: 2, pb: 3 }}
            key={learningModule.id}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <CircleIcon sx={{ color: learningModule.colorDark, mr: 1 }} />
              <Typography variant="h4">{learningModule.name}</Typography>
            </Box>
            {learningModule.learningSteps
              .filter((step) => step.includeInReport == true)
              .map(function (learningStep) {
                return (
                  <Accordion key={learningStep.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>{learningStep.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{learningStep.name}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
          </Paper>
        )
      })}
    </Box>
  )
}

export default LearningPathOverview

import $ from "jquery"
import { createRoot } from "react-dom/client"
import HTMLString from "react-html-string"
import { Provider } from "react-redux"

import Clients from "./components/autocomplete/clients"
import Coaches from "./components/autocomplete/coaches"
import Peers from "./components/autocomplete/peers"
import Programs from "./components/autocomplete/programs"
import Questionnaires from "./components/autocomplete/questionnaires"
import BadgeText from "./components/badge_text"
import CommentsDisabledIcon from "./components/comments_disabled_icon"
import CommentsIcon from "./components/comments_icon"
import CommentsList from "./components/comments_list"

import CommentsModal from "./components/comments_modal"
import CopyReportLinkButton from "./components/copy_report_link_button"
import DatePicker from "./components/datepicker"
import DownloadReportButton from "./components/download_report_button"
import GenerateReportButton from "./components/generate_report_button"
import LearningPathChart from "./components/learning_path_chart"
import LearningPathOverview from "./components/learning_path_overview"

import PdfPreview from "./components/pdf_preview"
import PdfRenderer from "./components/pdf_renderer"
import QuestionnairePreview from "./components/questionnaire_preview"
import SendReportDialogButton from "./components/send_report_dialog_button"
import SendReportEmailButton from "./components/send_report_email_button"
import StackedChips from "./components/stacked_chips"
import LearningPathTable from "./components/tables/learning_path_table"
import OverTimeLearningStepsTable from "./components/tables/over_time_learning_steps_table"
import { UnloqContainer } from "./components/unloq_container"
import ClientSessionsDashboard from "./dashboards/client_sessions_dashboard"
import store from "./lib/store"
import ProgramMembershipsPage from "./pages/people_analytics/program_memberships"

function renderComponents(components) {
  Object.keys(components).forEach((component) => {
    const elements = document.querySelectorAll(component)

    elements.forEach((element) => {
      const hasRendered = element.hasAttribute("rendered")

      if (!hasRendered) {
        const reactElement = (
          <HTMLString html={element.outerHTML} components={components} />
        )
        const component = createRoot(element)
        component.render(<Provider store={store}>{reactElement}</Provider>)
        element.setAttribute("rendered", true)
      }
    })
  })
}

let components = {
  clients: (props) => <Clients {...props} />,
  badgetext: (props) => <BadgeText {...props} />,
  clientsessionsdashboard: (props) => <ClientSessionsDashboard {...props} />,
  coaches: (props) => <Coaches {...props} />,
  commentsicon: (props) => <CommentsIcon {...props} />,
  commentsdisabledicon: (props) => <CommentsDisabledIcon {...props} />,
  commentslist: (props) => <CommentsList {...props} />,
  commentsmodal: (props) => <CommentsModal {...props} />,
  copyreportlinkbutton: (props) => <CopyReportLinkButton {...props} />,
  datepicker: (props) => <DatePicker {...props} />,
  downloadreportbutton: (props) => <DownloadReportButton {...props} />,
  generatereportbutton: (props) => <GenerateReportButton {...props} />,
  learningpathchart: (props) => <LearningPathChart {...props} />,
  learningpathoverview: (props) => <LearningPathOverview {...props} />,
  learningpathtable: (props) => <LearningPathTable {...props} />,
  overtimelearningstepstable: (props) => (
    <OverTimeLearningStepsTable {...props} />
  ),
  peers: (props) => <Peers {...props} />,
  pdfpreview: (props) => <PdfPreview {...props} />,
  pdfrenderer: (props) => <PdfRenderer {...props} />,
  programs: (props) => <Programs {...props} />,
  programmembershipspage: (props) => <ProgramMembershipsPage {...props} />,
  questionnairepreview: (props) => <QuestionnairePreview {...props} />,
  questionnaires: (props) => <Questionnaires {...props} />,
  sendreportdialogbutton: (props) => <SendReportDialogButton {...props} />,
  sendreportemailbutton: (props) => <SendReportEmailButton {...props} />,
  stackedchips: (props) => <StackedChips {...props} />,
  unloqcontainer: (props) => <UnloqContainer {...props} />,
}

$(function () {
  renderComponents(components)
})

$(document).on("up:layer:open up:fragment:inserted", loadComponents)

function loadComponents() {
  renderComponents(components)
}

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Paper from "@mui/material/Paper"
import LearningPathTableCell from "./learning_path_table_cell"

export default function LearningPathTable({
  headers,
  rows,
  start_date,
  end_date,
}) {
  const headersJson = JSON.parse(headers)
  const rowsJson = JSON.parse(rows)

  const skipStep = (stepName) => {
    if (
      stepName == "Supervisor Behaviors" &&
      new Date(start_date) < new Date("2023-07-01")
    ) {
      return true
    }
    if (stepName == "Participant Guide Introduction") {
      return true
    }
    if (
      stepName == "Participant Guide introduction" &&
      new Date(start_date) > new Date("2023-06-30")
    ) {
      return true
    }
    return false
  }

  const stepLength = (steps) => {
    let stepLength = 0
    steps.forEach((step) => {
      if (!skipStep(step.name)) {
        stepLength++
      }
    })
    return stepLength
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 30px)" }}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ backgroundColor: "#c88d02", textAlign: "center" }}
              colSpan={5}
            >
              {start_date && end_date && (
                <span>
                  {start_date} / {end_date}
                </span>
              )}
            </TableCell>
            {headersJson.map((row, i) => (
              <TableCell
                key={`module-row-${i}`}
                sx={{
                  backgroundColor: row.color_dark,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
                colSpan={stepLength(row.steps)}
              >
                {row.name}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#c88d02" }}>ID</TableCell>
            <TableCell sx={{ backgroundColor: "#c88d02" }}>Name</TableCell>
            <TableCell sx={{ backgroundColor: "#c88d02" }}>Country</TableCell>
            <TableCell sx={{ backgroundColor: "#c88d02" }}>Email</TableCell>
            <TableCell sx={{ backgroundColor: "#c88d02" }}>Score</TableCell>
            {headersJson.map((row, i) => {
              return row.steps.map((step, i) => {
                if (!skipStep(step.name)) {
                  return (
                    <TableCell
                      key={`step-row-${i}`}
                      sx={{
                        backgroundColor: row.color_light,
                        textAlign: "left",
                        writingMode: "vertical-rl",
                        transform: "rotate(180deg)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {step.presentation}
                      {step.score > 0 && ` (${step.score})`}
                    </TableCell>
                  )
                }
              })
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsJson.map((row, i) => (
            <TableRow key={`data-row=${i}`}>
              {row.map((cellData, index) => {
                if (!skipStep(cellData.step_name)) {
                  if (index < 5 || index + 1 == row.length) {
                    return (
                      <TableCell
                        sx={{
                          backgroundColor: cellData.color,
                          textAlign: cellData.align,
                          whiteSpace: "nowrap",
                        }}
                        key={index}
                      >
                        {cellData.data}
                      </TableCell>
                    )
                  } else {
                    return (
                      <LearningPathTableCell
                        cellData={cellData}
                        index={index}
                        key={index}
                      />
                    )
                  }
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

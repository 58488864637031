import React, { useEffect, useState } from "react"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { Comment } from "api/models"
import UnloqButton from "./unloq_button"
import { getSubjects } from "repository/subjects_repository"

const CommentsForm = ({
  i18n,
  setComments,
  commentable_id,
  commentable_type,
  replyComment,
  setReplyComment,
  showPublicOnly,
}) => {
  const [shareds] = useState(["coach_only", "private", "public"])
  const [shared, setShared] = useState(shareds[2])
  const [subjects, setSubjects] = useState([])
  const [subject, setSubject] = useState("")
  const [text, setText] = useState("")

  const sharedSelectStyle = () => {
    const style = { width: "100%" }

    if (showPublicOnly === "true") {
      style["display"] = "none"
    }
    return style
  }

  useEffect(() => {
    i18n.defaultLocale = "en"
    const urlParts = window.location.href.split("/")

    if (urlParts.length > 3) {
      i18n.locale = urlParts[3]
    }
    if (subjects.length == 0) {
      fetchSubjects().catch(console.error)
    }
  }, [subjects, i18n])

  const fetchSubjects = async () => {
    const fetchedSubjects = await getSubjects({ filters: {} })
    if (fetchedSubjects.length > 0) {
      setSubjects(fetchedSubjects)
    }
  }

  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSubject(event.target.value as string)
  }

  const handleSharedChange = (event: SelectChangeEvent) => {
    setShared(event.target.value as string)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const commentModel = new Comment({
      text: text,
      subjectId: subject,
      shared: shared,
      commentableId: commentable_id,
      commentableType: commentable_type,
      repliedCommentId: replyComment.id,
    })
    const saved = await commentModel.save()
    if (saved) {
      setText("")
      setComments([])
      setReplyComment("")
    }
  }

  const showReplyComment = (replyComment) => {
    if (!replyComment || replyComment.text.length == 0) {
      return
    }

    return (
      <Box sx={{ mt: 1, px: 2, display: "flex", flexDirection: "column" }}>
        {replyComment.userName}
        <TextField
          value={replyComment.text}
          minRows={2}
          fullWidth
          multiline
          disabled
          sx={{ backgroundColor: "grey.200" }}
        />
      </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box display="flex" sx={{ gap: 2 }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="subject">
              {i18n.t("react.admin.comments.subject")}
            </InputLabel>
            <Select
              labelId="subject"
              value={subject}
              label={i18n.t("react.admin.comments.subject")}
              onChange={handleSubjectChange}
            >
              <MenuItem
                key="other"
                sx={{ fontSize: "small", fontStyle: "italic" }}
              />
              {subjects.map(function (subject) {
                return (
                  <MenuItem
                    key={subject.id}
                    value={subject.id}
                    sx={{ fontSize: "small" }}
                  >
                    {subject.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={sharedSelectStyle}>
            <InputLabel id="shared">
              {i18n.t("react.admin.comments.shared.title")}
            </InputLabel>
            <Select
              labelId="shared"
              value={shared}
              label={i18n.t("react.admin.comments.shared.title")}
              onChange={handleSharedChange}
            >
              {shareds.map(function (shared) {
                const sharedTranslation = i18n.t(
                  `react.admin.comments.shared.${shared}`
                )
                return (
                  <MenuItem
                    key={shared}
                    value={shared}
                    sx={{ fontSize: "small" }}
                  >
                    {sharedTranslation}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        {showReplyComment(replyComment)}
        <TextField
          label={i18n.t("react.admin.comments.title")}
          value={text}
          minRows={3}
          placeholder={i18n.t("react.admin.comments.title_placeholder")}
          sx={{ my: 2 }}
          fullWidth
          multiline
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setText(event.target.value)
          }}
        />
        <UnloqButton type="submit">{i18n.t("react.admin.save")}</UnloqButton>
      </form>
    </Box>
  )
}

export default CommentsForm

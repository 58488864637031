import { useEffect, useRef } from "react"
import { SVGGantt } from "gantt"

const LearningPathChart = ({ steps }) => {
  const stepsJson = JSON.parse(steps)

  const ganttref = useRef(null)

  useEffect(() => {
    const data = []
    for (const step of stepsJson) {
      data.push({
        id: 1,
        text: step.step_name,
        start: new Date(step.start_date),
        end: new Date(step.end_date),
        percent: step.percentage_done,
      })
    }
    ganttref.current.innerHTML = null
    const strGantt = new SVGGantt(ganttref.current, data, {
      viewMode: "day",
    })
    strGantt.render()
  }, [ganttref, stepsJson])

  return <div ref={ganttref} style={{ overflowY: "hidden" }}></div>
}

export default LearningPathChart

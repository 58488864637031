import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"

const StackedChips = () => {
  const handleDelete = () => {
    console.info("You clicked the delete icon.")
  }

  return (
    <Stack direction="row" spacing={1}>
      <Chip label="Deletable" onDelete={handleDelete} />
      <Chip label="Deletable" variant="outlined" onDelete={handleDelete} />
    </Stack>
  )
}

StackedChips.displayName = "StackedChips"
export default StackedChips

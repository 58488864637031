import Badge from "@mui/material/Badge"
import { Fragment } from "react"
import UnloqTheme from "./unloq_theme"

const BadgeText = ({ badge_content = 0, badge_text = "" }) => {
  if (badge_content <= 0) {
    return <Fragment>{badge_text}</Fragment>
  }

  return (
    <UnloqTheme>
      <Badge color="primary" max={9} badgeContent={badge_content}>
        {badge_text}
      </Badge>
    </UnloqTheme>
  )
}
export default BadgeText

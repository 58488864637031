import { createTheme } from "@mui/material"

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#262421",
    },
    secondary: {
      main: "#37e178",
    },
    background: {
      default: "rgb(243,244,246)",
    },
  },
  typography: {
    fontSize: 16,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1c2536",
          color: "#9da4ae",
          "& .MuiListItemIcon-root": {
            color: "inherit",
          },
          "& .MuiDivider-root": {
            backgroundColor: "currentColor",
            opacity: 0.3,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
        },
      },
    },
  },
})

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import * as React from "react"
import { IFilter } from "./interfaces"

const Filters = (props: { filters; setFilter; children }) => {
  const { filters, setFilter } = props

  const [expanded, setExpanded] = React.useState<boolean>(true)

  const deleteFilter = () => {
    const filter: IFilter = {
      name: "program",
      value: null,
    }
    setFilter(filter)
  }

  const handleChange = () => {
    setExpanded(!expanded)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{ mb: 3, backgroundColor: "#f6f6f6" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filter-chips"
        id="filter-chips-header"
        sx={{
          display: "flex",
          borderBottom: "1px solid #e0e0e0",
          justifyItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: "34px",
            fontWeight: "bold",
            mr: 2,
          }}
        >
          Filters:
        </Typography>
        <Stack direction="row" spacing={1}>
          {filters.map((filter, i) => {
            if (filter.value != null) {
              return (
                <Chip
                  key={`filter-${i}`}
                  label={`${filter.presentation}`}
                  onDelete={deleteFilter}
                />
              )
            }
          })}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", width: "100%", my: 2 }}>
          {props.children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default Filters

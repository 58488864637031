import * as React from "react"
import { Fragment } from "react"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

import { getClientSessions } from "../../repository/client_sessions_repository"

function createData(
  date,
  client: string,
  coach: string,
  program: number,
  sessions: number,
  hourlyRate: number,
  billable: number,
  nonBillable: number,
  available: number,
  remaining: number,
  progress: number,
) {
  return {
    date,
    client,
    coach,
    program,
    sessions,
    hourlyRate,
    billable,
    nonBillable,
    available,
    remaining,
    progress,
  }
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props
  const [open, setOpen] = React.useState(false)

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.client}</TableCell>
        <TableCell>{row.coach}</TableCell>
        <TableCell>{row.program}</TableCell>
        <TableCell align="center">{row.sessions}</TableCell>
        <TableCell align="center">{row.hourlyRate}</TableCell>
        <TableCell align="center">{`${row.billable} min.`}</TableCell>
        <TableCell align="center">{`${row.nonBillable} min.`}</TableCell>
        <TableCell align="center">{row.available}</TableCell>
        <TableCell align="center">{row.remaining}</TableCell>
        <TableCell align="center">{row.progress}</TableCell>
      </TableRow>
    </Fragment>
  )
}

export const ClientSessionTable = (props: { filters }) => {
  const { filters } = props

  const [rows, setRows] = React.useState([])

  React.useEffect(() => {
    if (rows.length == 0) {
      const fetchClientSessions = async () => {
        const client_sessions = await getClientSessions(filters)
        const filtered = client_sessions.map((client_session) => {
          return {
            date: client_session.date,
            client: client_session.client?.profile?.name,
            coach: client_session.owner?.profile?.name,
            program: client_session.program?.name,
            sessions: client_session.sessions,
            hourlyRate: client_session.hourlyRate,
            billable: client_session.billable,
            nonBillable: client_session.nonBillable,
            available: client_session.available,
            remaining: client_session.remaining,
            progress: client_session.progress,
          }
        })
        setRows(filtered)
      }

      fetchClientSessions().catch(console.error)
    }
  }, [filters, rows])

  React.useEffect(() => {
    if (filters.length > 0) {
      const fetchClientSessions = async () => {
        const client_sessions = await getClientSessions(filters)
        if (client_sessions.length > 0) {
          const filtered = client_sessions.map((client_session) => {
            return {
              date: client_session.date,
              client: client_session.client?.profile?.name,
              coach: client_session.owner?.profile?.name,
              program: client_session.program?.name,
              sessions: client_session.sessions,
              hourlyRate: client_session.hourlyRate,
              billable: client_session.billable,
              nonBillable: client_session.nonBillable,
              available: client_session.available,
              remaining: client_session.remaining,
              progress: client_session.progress,
            }
          })
          setRows(filtered)
        } else {
          setRows([])
        }
      }

      fetchClientSessions().catch(console.error)
    }
  }, [filters])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow className="border-grey-lighter bg-grey-extra-light">
            <TableCell />
            <TableCell>Date</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Coach</TableCell>
            <TableCell>Program</TableCell>
            <TableCell align="center">Sessions</TableCell>
            <TableCell align="center">Hourly rate</TableCell>
            <TableCell align="center">Billable</TableCell>
            <TableCell align="center">Non-Billable</TableCell>
            <TableCell align="center">Available</TableCell>
            <TableCell align="center">Remaining</TableCell>
            <TableCell align="center">Progress</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row key={`row-${i}`} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ClientSessionTable

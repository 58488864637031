import React, { useEffect } from "react"
import Autocomplete from "../autocomplete"
import { getQuestionnaires } from "repository/questionnaires_repository"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Questionnaires = (props: any) => {
  const [options, setOptions] = React.useState([])

  useEffect(() => {
    if (options.length == 0) {
      const fetchQuestionnaires = async () => {
        const questionnaires = await getQuestionnaires({})
        const filtered = questionnaires.map((questionnaire) => {
          return {
            id: questionnaire.hashid,
            label: questionnaire.name,
          }
        })
        setOptions(filtered)
      }

      fetchQuestionnaires().catch(console.error)
    }
  }, [options])

  return <Autocomplete {...props} options={options} />
}

Questionnaires.displayName = "Questionnaires"
export default Questionnaires
